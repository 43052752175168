<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import CheckIcon from "@/assets/icons/check.svg?component"
import CaretDownIcon from "@/assets/icons/caret-down.svg?component"

export interface FunctionObjectOption {
  id: string | number
  name: string | number
  integration: string
  subtext: string
  avatar: string
}

type FunctionGroup = [string, FunctionObjectOption[] | undefined]

interface Props {
  description?: string | undefined // hint text
  label?: string | undefined
  modelValue?: string
  options?: FunctionObjectOption[]
  size?: "small" | "medium" | "large"
  placeholder?: string
  disabled?: boolean
  error?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  description: undefined,
  label: undefined,
  modelValue: "",
  options: () => [],
  size: "medium",
  placeholder: "Select function...",
  disabled: false,
  error: false,
})

const emit = defineEmits(["update", "update:modelValue"])

const listboxButtonSizeClasses: { [K in NonNullable<Props["size"]>]: string } =
  {
    small: "h-6 py-1.5 pl-2 pr-10",
    medium: "h-8 py-2 pl-3 pr-10",
    large: "h-12 py-3 pl-4 pr-10",
  }

const listboxButtonTextSizeClasses: {
  [K in NonNullable<Props["size"]>]: string
} = {
  small: "text-xs",
  medium: "text-xs",
  large: "text-m",
}

const listboxOptionSizeClasses: { [K in NonNullable<Props["size"]>]: string } =
  {
    small: "py-1.5 pl-2 pr-10",
    medium: "py-2 pl-3 pr-10",
    large: "py-3 pl-4 pr-10",
  }

const formattedOptions = computed(() => {
  return props.options.map((option) => {
    if (Array.isArray(option)) throw new Error("Arrays aren't supported yet")
    if (option !== null && typeof option === "object") return option
    return { id: option, name: option }
  })
})

const groupedOptions = computed(() => {
  if (!props.options || !props.options.length) return []
  const grouped = Object.groupBy(
    props.options,
    ({ integration }) => integration || "generic"
  )
  return Object.entries(grouped)
    .sort(_sortFunctionGroups)
    .map(([group, options]) => ({ group, options }))
})

const listboxButtonClasses = computed(() => {
  const listboxButtonClasses = [listboxButtonSizeClasses[props.size]]
  if (props.disabled) {
    listboxButtonClasses.push(
      "opacity-30 cursor-not-allowed focus:outline-none"
    )
  }
  if (props.error) {
    listboxButtonClasses.push("border-red-500 bg-red-25")
  }
  return listboxButtonClasses.join(" ")
})

const listboxButtonTextClasses = computed(() => {
  return listboxButtonTextSizeClasses[props.size]
})

const listboxOptionClasses = computed(() => {
  return listboxOptionSizeClasses[props.size]
})

const selectedOption = computed({
  get: () => formattedOptions.value.find((o) => o.id === props.modelValue),
  set: (value) => {
    emit("update", value?.id)
    emit("update:modelValue", value?.id)
  },
})

// format groups for nicer reading
function groupName(group: string): string {
  return group.replace("_", " ")
}

// ensure the generic group is always below source-specific functions
// sort source-specific functions alphabetically
function _sortFunctionGroups(a: FunctionGroup, b: FunctionGroup) {
  if (a[0] === "generic") return 1
  if (b[0] === "generic") return -1
  return a[0].localeCompare(b[0])
}
</script>

<template>
  <Listbox v-model="selectedOption" as="div" :disabled="props.disabled">
    <ListboxLabel
      v-if="label"
      class="block text-sm font-medium leading-6 text-slate-600"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ label }}
    </ListboxLabel>
    <p
      v-if="description"
      class="text-sm text-slate-400 mb-2"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ description }}
    </p>

    <div class="relative">
      <ListboxButton
        class="relative rounded w-full cursor-default border bg-white text-left text-slate-600 border-slate-200 focus:outline-none focus:ring-2 focus:ring-indigo-600"
        :class="listboxButtonClasses"
      >
        <span
          v-if="!selectedOption"
          :class="listboxButtonTextClasses"
          class="block opacity-50 truncate py-px font-medium leading-none"
        >
          {{ placeholder || "&nbsp;" }}
        </span>
        <span
          v-else
          :class="listboxButtonTextClasses"
          class="block truncate py-px font-medium leading-none"
        >
          {{ selectedOption?.name }}
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <CaretDownIcon class="h-4 w-4 text-slate-700" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          as="div"
          class="absolute max-h-96 overflow-y-auto z-10 mt-1 w-full rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-slate-100"
        >
          <div
            v-for="group in groupedOptions"
            :key="group.group"
            class="pt-3 pb-2"
          >
            <h3
              class="text-xxs font-semibold uppercase text-slate-400 px-3 pb-1"
            >
              {{ groupName(group.group) }}
            </h3>
            <ListboxOptions>
              <ListboxOption
                v-for="option in group.options"
                :key="option.id"
                v-slot="{ active, selected }"
                :value="option"
                as="div"
              >
                <div
                  :class="[
                    listboxOptionClasses,
                    selected ? '!bg-indigo-600 !text-white' : 'text-slate-600',
                    active ? 'bg-indigo-50 text-indigo-600' : 'text-slate-600',
                  ]"
                  class="elative cursor-pointer select-none flex items-center"
                  :title="option.subtext"
                >
                  <img :src="option.avatar" class="h-7 mr-2 rounded" />
                  <div class="block w-full pr-3">
                    <span
                      :class="[
                        selected
                          ? 'bg-indigo-600 text-white'
                          : `text-slate-600`,
                      ]"
                      class="block truncate py-px font-medium leading-none text-xs"
                    >
                      {{ option.name }}
                    </span>
                    <span
                      class="block truncate py-px font-medium leading-none text-xs text-slate-400"
                    >
                      {{ option.subtext }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      'text-white absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-4 w-4" aria-hidden="true" />
                  </span>
                </div>
              </ListboxOption>
            </ListboxOptions>
          </div>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
