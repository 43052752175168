<script setup lang="ts">
import { computed } from "vue"
import { removeFalsy } from "@/utils/array"
import { latest } from "@/utils/date"

import type { Stream, StreamConfig } from "@/types/types"

interface Props {
  stream: Stream | null
  config: StreamConfig | undefined
}

const props = withDefaults(defineProps<Props>(), {})

const active = computed(() => props.stream?.mode === "active")
const createdAt = computed(() => localeDate(props.stream?.created_at))
const updatedAt = computed(() => {
  const lastUpdate = latest(
    removeFalsy([props.stream?.updated_at, props.config?.updated_at])
  )
  return localeDate(lastUpdate)
})

function localeDate(dateString: string | Date | undefined) {
  if (!dateString) return ""
  const date = toDate(dateString)
  return date.toLocaleString("en-US")
}
</script>

<template>
  <div
    class="wrapper bg-white border border-slate-200 shadow-01 rounded w-full"
  >
    <div class="px-3 pt-3 pb-4 border-b border-slate-100">
      <h3 class="text-slate-400 text-xs mb-1 font-semibold">Status</h3>
      <p class="text-slate-600 text-sm font-medium">
        <VTag :color="active ? 'emerald' : 'slate'">
          {{ active ? "Active" : "Inactive" }}
        </VTag>
      </p>
    </div>
    <div class="px-3 pt-3 pb-4 border-b border-slate-100">
      <h3 class="text-slate-400 text-xs mb-1 font-semibold">Created at</h3>
      <p class="text-slate-600 text-sm font-medium">{{ createdAt }}</p>
    </div>
    <div class="px-3 pt-3 pb-4">
      <h3 class="text-slate-400 text-xs mb-1 font-semibold">Updated at</h3>
      <p class="text-slate-600 text-sm font-medium">{{ updatedAt }}</p>
    </div>
  </div>
</template>
