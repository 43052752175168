import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12.068 10 7.795 5.722a1.01 1.01 0 0 1 0-1.427 1.006 1.006 0 0 1 1.424 0l4.986 4.992a1.01 1.01 0 0 1 0 1.426l-4.986 4.992a1.006 1.006 0 0 1-1.424 0 1.01 1.01 0 0 1 0-1.427z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }