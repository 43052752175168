<script setup lang="ts">
// editor for existing functions

import ChevronDown from "@/assets/icons/chevron-down.svg?component"
import ChevronRight from "@/assets/icons/chevron-right.svg?component"
import FunctionEditor from "@/components/FunctionEditor.vue"
import { FUNCTIONS } from "@/common/function"
import { clone } from "@/utils/object"

import type { StreamFunction } from "@/types/types"

interface Props {
  function: StreamFunction
  node: number
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["delete", "update"])

const editedFunction = reactive<StreamFunction>({
  id: "",
  type: "drop", // specify default to satisfy type requirements
  label: "",
  filter: {},
  config: {},
})

const isEditing = ref(false)

// make public methods on child component available via ref
const functionEditor = ref<InstanceType<typeof FunctionEditor> | null>(null)

const currentFunction = computed(() =>
  FUNCTIONS.find((f) => f.id === props.function.type)
)
const functionName = computed(() => currentFunction.value?.name)

const functionImage = computed(() => {
  let integration: string | undefined

  if (currentFunction.value?.integration) {
    if (currentFunction.value.integration === "code") {
      return undefined // dont render an icon for code
    } else if (currentFunction.value.integration === "datadog") {
      integration = "datadog_agent"
    } else {
      integration = currentFunction.value.integration.toLowerCase()
    }
  }

  return integration ? `/images/sources/${integration}.svg` : undefined
})

watch(
  () => props.function,
  (newFunction) => _updateLocalState(newFunction),
  { immediate: true, deep: true }
)

function cancel() {
  _updateLocalState(props.function) // reset to persisted
  isEditing.value = false
}

function remove() {
  emit("delete", props.function.id)
}

function toggleEdit() {
  isEditing.value = !isEditing.value
}

async function saveEdits() {
  if (functionEditor.value) {
    // check field validations
    const valid = await functionEditor.value.validate()
    if (!valid) return
  }

  emit("update", clone(editedFunction))
  isEditing.value = false
}

function updateFunction(func: StreamFunction) {
  editedFunction.filter = func.filter
  editedFunction.config = func.config
}

function _updateLocalState(newFunction: StreamFunction) {
  editedFunction.id = newFunction.id
  editedFunction.type = newFunction.type
  editedFunction.label = newFunction.label
  editedFunction.filter = structuredClone(toRaw(newFunction.filter))
  editedFunction.config = structuredClone(toRaw(newFunction.config))
}
</script>

<template>
  <div class="border border-slate-200 shadow-01 rounded overflow-hidden">
    <div class="bg-white flex justify-between">
      <div class="flex grow">
        <div class="p-4 border-r border-slate-100">
          <VPoint>{{ props.node + 1 }}</VPoint>
        </div>
        <div
          class="grow py-4 pl-3 flex items-center space-x-3 cursor-pointer select-none"
          @click="toggleEdit"
        >
          <div>
            <ChevronRight v-if="!isEditing" class="text-slate-600 h-6 w-6" />
            <ChevronDown v-else class="text-indigo-600 h-6 w-6" />
          </div>

          <img
            v-if="functionImage"
            :src="functionImage"
            alt="Integration"
            class="h-7 w-7 rounded"
          />

          <div class="text-slate-600 leading-none py-2 font-medium">
            {{ functionName }}
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center space-x-2 p-4">
        <VInput
          v-show="isEditing"
          v-model="editedFunction.label"
          placeholder="Optional label for function..."
          class="w-96"
        />
        <span v-show="!isEditing" class="text-slate-400 px-4">
          {{ editedFunction.label }}
        </span>
      </div>
    </div>
    <div v-show="isEditing">
      <FunctionEditor
        v-if="isEditing"
        ref="functionEditor"
        :stream-function="editedFunction"
        @update="updateFunction"
      />
      <VButtonBar>
        <VButton color="secondary" @click="cancel">Cancel</VButton>
        <VButton color="positive" @click="saveEdits">Save</VButton>
        <template #left>
          <VButton color="negative" @click="remove"> Delete Function </VButton>
        </template>
      </VButtonBar>
    </div>
  </div>
</template>
