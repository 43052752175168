<script setup lang="ts">
import EventSampler from "@/components/EventSampler.vue"
import StreamControls from "@/components/StreamControls.vue"
import StreamMetrics from "@/components/StreamMetrics.vue"
import StreamProperties from "@/components/StreamProperties.vue"
import StreamUpdateForm from "@/components/StreamUpdateForm.vue"
import SubHeader from "@/components/SubHeader.vue"
import SubNavigation from "@/components/SubNavigation.vue"
import TabNavigation from "@/components/TabNavigation.vue"
import { telemetryMetricTypesForSource } from "@/common/metrics"
import { useResourceMetrics } from "@/composables/resource_metrics"
import { useStreamStore } from "@/stores/stream"

import type {
  Destination,
  MetricInstance,
  Source,
  Stream,
  StreamConfig,
  TelemetryMetricType,
} from "@/types/types"
import type { StreamConfigResults, StreamUpdate } from "@/types/requests"

const route = useRoute()

const metrics = reactive<MetricInstance[]>([])
const sourceMetrics = reactive<MetricInstance[]>([])
const metricsSocket = ref<WebSocket | undefined>()
const telemetryMetricTypes = ref<TelemetryMetricType[]>([])

const tabs = reactive(["overview", "settings"]) // add 'live_tail' later?
const selectedTab = ref("overview")
const showEventSampler = ref(false)

const { $toast } = useNuxtApp()

const config = ref()
const source = ref<Source>()
const destination = ref<Destination>()

const { data: stream, error } = await useAPIFetch<Stream>(
  `/streams/${route.params.id}`
)

if (error.value) {
  // display error page
  throw createError({ statusCode: 404, fatal: true })
}

useHead({ title: () => stream.value?.name || "" })

const breadcrumbs = computed(() => [
  { name: "Streams", to: "/streams" },
  { name: stream.value?.name || "" },
])

const writesActive = ref(stream.value?.mode === "active")

// load related source & dest when stream is resolved
watch(
  stream,
  (newStream) => {
    let getSource = Promise.resolve()
    if (newStream?.id) {
      const configUrl = `/streams/${newStream.id}/configs?limit=2`
      useAPIFetch<StreamConfigResults>(configUrl).then((resp) => {
        if (resp.error.value) {
          $toast.error("Stream loading failed")
        } else {
          config.value = resp.data.value?.configs[0]
        }
      })
    }
    if (newStream?.source_id) {
      getSource = useGetSource(newStream.source_id).then((resp) => {
        if (resp.error.value) {
          $toast.error("Unable to load source")
        } else {
          source.value = resp.data.value || undefined
          telemetryMetricTypes.value = telemetryMetricTypesForSource(
            source.value?.type
          )
        }
      })
    }
    if (newStream?.destination_id) {
      useGetDestination(newStream.destination_id).then((resp) => {
        if (resp.error.value) {
          $toast.error("Unable to load destination")
        } else {
          destination.value = resp.data.value || undefined
        }
      })
    }
    // ensure source is loaded before subscribing to metrics
    Promise.all([getSource]).then(checkMetrics)
  },
  { deep: true, immediate: true }
)

onUnmounted(() => metricsSocket.value?.close())

function checkMetrics() {
  if (stream.value?.mode === "active") {
    _initMetrics()
  } else {
    _stopMetrics()
  }
}

function closeEventSampler() {
  showEventSampler.value = false
}

// called when new metric is received
function metricsHandler(data: MetricInstance[]) {
  // TODO: make a generic buffer for these?
  const streamId = stream.value?.id
  const filtered = data.filter((i) => i.stream_id === streamId)
  metrics.push(...filtered)
  if (metrics.length > 5) {
    metrics.splice(0, metrics.length - 5) // keep 5 most recent
  }
  const sourceId = source.value?.id
  const sourceFiltered = data.filter((i) => i.source_id === sourceId)
  sourceMetrics.push(...sourceFiltered)
  if (sourceMetrics.length > 5) {
    sourceMetrics.splice(0, sourceMetrics.length - 5) // keep 5 most recent
  }
}

function sampleEvents() {
  showEventSampler.value = true
}

function selectTab(tab: string) {
  selectedTab.value = tab
}

function toggleWrites(newValue: boolean) {
  const mode = newValue ? "active" : "paused"
  const status = newValue ? "enabled" : "disabled"
  const msg = `Destination writes ${status}`
  _updateStream({ mode }, msg, "Destination writes could not be changed")
}

function updateConfig(newConfig: StreamConfig) {
  config.value = newConfig
}

function updateStream(updates: StreamUpdate) {
  _updateStream(updates, "Stream updated", "Stream update failed")
}

function _initMetrics() {
  const streams: string[] = []
  const sources: string[] = []
  if (stream.value?.id) streams.push(stream.value.id)
  if (source.value?.id) sources.push(source.value.id)
  const subscription = { streams, sources }
  const socket = useResourceMetrics(subscription, metricsHandler)
  metricsSocket.value = socket
}

function _stopMetrics() {
  metrics.length = 0 // clear metrics
  metricsSocket.value?.close()
}

function _updateStream(
  updates: StreamUpdate,
  successMsg: string,
  errorMsg: string
) {
  const id = stream.value?.id
  if (!id) return

  $api<Stream>(`/streams/${id}`, { method: "PATCH", body: updates })
    .then((updatedStream) => {
      useStreamStore().updateStream(id, updatedStream)
      stream.value = updatedStream
      $toast.success(successMsg)
    })
    .catch(() => $toast.error(errorMsg))
}
</script>

<template>
  <div>
    <SubHeader>
      <SubNavigation>
        <VBreadcrumb :items="breadcrumbs" />

        <template #right>
          <div class="flex space-x-4">
            <VButton
              color="secondary"
              :disabled="!writesActive"
              @click="sampleEvents"
            >
              Sample Events
            </VButton>
            <VToggle
              v-model="writesActive"
              label="Write to Destination"
              boxed
              :color="writesActive ? 'emerald' : 'amber'"
              @toggle="toggleWrites"
            />
          </div>
        </template>
      </SubNavigation>
      <TabNavigation :tabs="tabs" :selected="selectedTab" @select="selectTab" />
    </SubHeader>

    <VPageContent v-show="selectedTab == 'overview'" sidebar>
      <StreamControls
        :stream="stream"
        :config="config"
        :source="source"
        :source-metrics="sourceMetrics"
        :destination="destination"
        :writes-active="writesActive"
        @update="updateConfig"
      />
      <template #sidebar>
        <StreamMetrics
          :metrics="metrics"
          :observability-types="telemetryMetricTypes"
          :writes-active="writesActive"
        />
        <StreamProperties :stream="stream" :config="config" class="mt-4" />
      </template>
    </VPageContent>

    <VPageContent v-show="selectedTab == 'settings'" constrain>
      <StreamUpdateForm :stream="stream" @update="updateStream" />
    </VPageContent>

    <EventSampler
      :display="showEventSampler"
      resource-type="stream"
      :resource-id="stream?.id"
      @close="closeEventSampler"
    />
  </div>
</template>
