// utility functions related to form inputs

import { isNumeric } from "@/utils/number"
import { isQuotedString, quoteString } from "@/utils/string"

// Take HTML input string and cast to native value allowing for quoted
// strings, etc
export function inputToNativeValue(
  inputValue: string
): string | number | boolean {
  let value: string | number | boolean
  if (inputValue === "true") value = true
  if (inputValue === "false") value = false
  if (isNumeric(inputValue)) value = +inputValue // to num
  if (isQuotedString(inputValue)) {
    const trimmed = removeQuotes(inputValue.trim())
    if (isNumeric(trimmed) || ["true", "false"].includes(trimmed)) {
      value = removeQuotes(inputValue)
    }
  }
  value ??= inputValue // else use existing string
  return value
}

// cast a native value (string, number or boolean) to a string in a consistent
// way for use in form inputs; reverse of inputToNativeValue
export function nativeValueToInput(value: string | number | boolean): string {
  if (value === true) return "true"
  if (value === false) return "false"
  if (typeof value === "number") return String(value)
  // string special cases
  if (value === "true") return quoteString("true")
  if (value === "false") return quoteString("false")
  if (isNumeric(value)) return quoteString(value)
  return value
}
