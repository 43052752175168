<script setup lang="ts">
// interface to add a new function to a stream

import FunctionEditor from "@/components/FunctionEditor.vue"
import FunctionSelect from "@/components/FunctionSelect.vue"
import {
  DEFAULT_CONFIGS,
  FUNCTIONS,
  RELEASED_FUNCTIONS,
} from "@/common/function"

import type { StreamFunction, StreamFunctionType } from "@/types/types"
import type { FunctionObjectOption } from "@/components/FunctionSelect.vue"

interface Props {
  addCount: number
  node: number
  disabled?: boolean
  // use 'closed' when the component will be permanently present,
  // 'select' when it will be added / removed inline (between functions)
  startMode?: "closed" | "select"
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  startMode: "closed",
})

const emit = defineEmits(["add", "cancel"])

const newFunction = reactive<StreamFunction>({
  type: "drop", // specify default to satisfy type requirements
  label: "",
  filter: {},
  config: {},
})

const mode = ref<"closed" | "select" | "edit">(props.startMode)

// make public methods on child component available via ref
const functionEditor = ref<InstanceType<typeof FunctionEditor> | null>(null)

const currentFunction = computed(() =>
  FUNCTIONS.find((f) => f.id === newFunction.type)
)
const functionName = computed(() => currentFunction.value?.name)

const functionOptions = computed<FunctionObjectOption[]>(() => {
  const allowed = _allowedFunctionTypes()
  return FUNCTIONS.filter((f) => allowed.includes(f.id)).map((func) => {
    let integration = "generic" // default case
    if (func.integration === "datadog") integration = "datadog_agent"
    else if (func.integration) integration = func.integration.toLowerCase()

    const option = {
      id: func.id,
      name: func.name,
      integration: func.integration ? func.integration : "",
      subtext: func.description,
      avatar: `/images/sources/${integration}.svg`,
    }
    return option
  })
})

watch(
  () => props.addCount,
  (newVal, oldVal) => {
    // added a new function successfully
    if (newVal > oldVal) _resetState()
  }
)

function cancel() {
  _resetState()
  emit("cancel")
}

async function saveFunction() {
  if (functionEditor.value) {
    // check field validations
    const valid = await functionEditor.value.validate()
    if (!valid) return
  }
  emit("add", clone(newFunction))
}

function startSelect() {
  mode.value = "select"
}

function selectFunction(selected: StreamFunctionType) {
  const chosen = FUNCTIONS.find((f) => f.id === selected)
  if (chosen) {
    newFunction.type = selected
    _setDefaultConfig(selected)
    mode.value = "edit"
  } else {
    newFunction.type = "drop" // default value
  }
}

function updateFunction(func: StreamFunction) {
  newFunction.filter = func.filter
  newFunction.config = func.config
}

function _allowedFunctionTypes(): StreamFunctionType[] {
  const allowed = RELEASED_FUNCTIONS
  if (useFeatureFlag("honeycomb_demo_functions")) {
    const demoFunctions: StreamFunctionType[] = ["convert_to_otlp_preview"]
    allowed.push(...demoFunctions)
  }
  return allowed
}

function _resetState() {
  // put all state back to initialization state
  mode.value = props.startMode
  newFunction.type = "drop"
  newFunction.label = ""
  newFunction.filter = {}
  newFunction.config = {}
}

function _setDefaultConfig(functionType: StreamFunctionType) {
  if (!Object.keys(DEFAULT_CONFIGS).includes(functionType)) {
    throw new Error("Unknown function type")
  }
  newFunction.config = DEFAULT_CONFIGS[functionType]
}
</script>

<template>
  <div>
    <div
      v-show="mode === 'closed'"
      class="border border-dashed border-indigo-200 bg-indigo-25 flex rounded"
    >
      <div class="p-4">
        <img class="h-8 w-8" src="/images/streams/function-faded.svg" alt="" />
      </div>
      <div class="grow py-4 text-center">
        <VButton :disabled="disabled" @click="startSelect">
          Add Function
        </VButton>
      </div>
    </div>

    <div
      v-if="mode === 'select'"
      class="border border-slate-200 shadow-01 rounded"
    >
      <div class="bg-white flex justify-between rounded">
        <div class="flex">
          <div class="p-4 border-r border-slate-100">
            <VPoint>{{ node + 1 }}</VPoint>
          </div>
          <div class="grow py-4 pl-4">
            <div class="text-slate-600 leading-none py-2 font-medium">
              New Function
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-2 p-4">
          <FunctionSelect
            :options="functionOptions"
            placeholder="Select function..."
            class="w-96"
            @update="selectFunction"
          />
          <VButton color="secondary" @click="cancel">Cancel</VButton>
        </div>
      </div>
    </div>

    <div
      v-if="mode === 'edit'"
      class="border border-slate-200 shadow-01 rounded"
    >
      <div class="bg-white flex justify-between rounded-t-lg">
        <div class="flex">
          <div class="p-4 border-r border-slate-100">
            <VPoint>{{ props.node + 1 }}</VPoint>
          </div>
          <div class="grow py-4 pl-4">
            <div class="text-slate-600 leading-none py-2 font-medium">
              {{ functionName }}
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-2 p-4">
          <VInput
            v-model="newFunction.label"
            placeholder="Optional label for function..."
            class="w-96"
          />
        </div>
      </div>
      <FunctionEditor
        ref="functionEditor"
        :stream-function="newFunction"
        @update="updateFunction"
      />
      <VButtonBar>
        <VButton color="secondary" @click="cancel">Cancel</VButton>
        <VButton color="positive" @click="saveFunction">Save</VButton>
      </VButtonBar>
    </div>
  </div>
</template>
