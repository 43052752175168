import { toTypedSchema } from "@vee-validate/yup"
import { array, boolean, object, string } from "yup"
import { OPERATORS } from "@/common/filter"

import type { BasicFilterCondition } from "@/types/types"
import type { ObjectSchema } from "yup"

const FILTER_BASIC_CONDITION: ObjectSchema<BasicFilterCondition> = object({
  selector: string().required("Please select an attribute to filter on"),
  operator: string().oneOf(OPERATORS).required("A valid operator is required"),
  value: string().required("Provide a value for comparison"),
})

export const FILTER = toTypedSchema(
  object({
    basic_expression: object({
      conditions: array().of(FILTER_BASIC_CONDITION).required(),
      match_all: boolean().required(),
    }).optional(),
  })
)
