<script setup lang="ts">
// data is the same as KPITile but layout is different, consider consolidating
import { numAbbreviation, smartDecimal } from "@/utils/number"

interface Props {
  title: string
  value: string | number
  disabled?: boolean
  error?: boolean
  units?: string
}

const props = withDefaults(defineProps<Props>(), {
  units: "",
})

const stateColors = {
  active: "text-indigo-800",
  disabled: "text-slate-300",
  error: "text-red-500",
}

const state = computed(() => {
  if (props.disabled) return "disabled"
  if (props.error && props.value === 0) return "disabled"
  if (props.error) return "error"
  else return "active"
})

const valueClasses = computed(() => stateColors[state.value])

function formatValue(value: number | string) {
  if (typeof value === "string") return value // already formatted
  const [num, unit] = numAbbreviation(value)
  return `${smartDecimal(num, 3)}${unit}`
}
</script>

<template>
  <div class="px-3 py-2 bg-slate-25">
    <h3 class="text-xs text-slate-400 font-medium">
      {{ props.title }}
    </h3>
    <div>
      <span :class="valueClasses" class="text-2xl">
        {{ formatValue(props.value) }}
      </span>
      <span class="ml-1 text-xs text-slate-400">{{ props.units }}</span>
    </div>
  </div>
</template>
