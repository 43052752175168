// helper functions for handling arrays

export function randomElement<T>(array: Array<T>): T | undefined {
  if (!array.length) return undefined
  return array[Math.floor(Math.random() * array.length)]
}

export function removeFalsy<T>(array: Array<T | undefined | null>): Array<T> {
  return array.filter((item): item is T => Boolean(item))
}
