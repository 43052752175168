<script setup lang="ts">
// displayed between functions in the stream editor. on hover displays a
// call to action to insert a new function and if clicked opens the interface
// to add a new function between the existing ones.

import StreamControlsAddFunction from "@/components/StreamControlsAddFunction.vue"
import StreamControlsSpacer from "@/components/StreamControlsSpacer.vue"
import AddCircleIcon from "@/assets/icons/add-circle-solid.svg?component"

import type { StreamFunction } from "@/types/types"

interface Props {
  index: number
  activeIndex: number | undefined
  addCount: number
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["add", "insert", "cancel"])

const addActive = computed(() => props.activeIndex === props.index)

function addFunction(newFunction: StreamFunction) {
  emit("add", newFunction, props.index)
}

function cancel() {
  emit("cancel")
}

function insertFunction() {
  emit("insert")
}
</script>

<template>
  <div>
    <!-- spacer which adapts on hover to show ability to insert -->
    <div
      v-show="!addActive"
      class="group/container relative flex border-l-4 min-h-8 ml-8 border-indigo-200 hover:border-indigo-300"
    >
      <div
        class="group/button transform-x-10 py-2 pr-44 hover-transition hover:py-4 hover:pr-28"
      >
        <AddCircleIcon
          class="group-hover/container:block group-hover/button:hidden hidden bg-white text-indigo-300 w-4 h-4 mr-1 rounded-full"
        />
        <div
          class="group-hover/button:inline-flex hidden bg-indigo-500 transform-x-4 py-1 pl-1 pr-2 cursor-pointer rounded-xl"
          @click="insertFunction"
        >
          <AddCircleIcon class="text-white w-4 h-4 mr-1" />
          <span class="text-white text-xs font-medium">Insert Function</span>
        </div>
      </div>
    </div>
    <!-- end spacer -->

    <!-- add interface which appears when clicked -->
    <div v-if="addActive">
      <StreamControlsSpacer />
      <StreamControlsAddFunction
        :add-count="addCount"
        :node="index"
        start-mode="select"
        @add="addFunction"
        @cancel="cancel"
      />
      <StreamControlsSpacer />
    </div>
    <!-- end add interface -->
  </div>
</template>

<style scoped>
.hover-transition {
  transition: padding 0.1s linear;
}

.transform-x-4 {
  transform: translateX(-4px);
}

.transform-x-10 {
  transform: translateX(-10px);
}
</style>
