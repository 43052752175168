<script setup lang="ts">
import { useForm, useFieldArray } from "vee-validate"
import RemoveCircleIcon from "@/assets/icons/remove-circle-outline.svg?component"
import { filterValuesToNative, filterValuesToStrings } from "@/common/filter"
import { FILTER } from "@/form_schemas/filter"
import FilterEditorRow from "@/components/FilterEditorRow.vue"
import { deepEqual } from "@/utils/object"

import type { BasicFilterCondition, Filter } from "@/types/types"

interface Props {
  modelValue?: Filter
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({}),
})

const emit = defineEmits(["update:modelValue"])

const anyOptions = ["Any", "All"]
const selectedAny = ref("Any")

// setup primary form
const { errors, meta, setFieldValue, resetForm, validate, values } = useForm({
  validationSchema: FILTER,
  initialValues: {
    basic_expression: {
      conditions: [{ selector: "", operator: "==", value: "" }],
      match_all: false,
    },
  },
})

// state & methods for managing the array of basic expression conditions
const {
  fields: conditions,
  push: pushCondition,
  remove: removeCondition,
} = useFieldArray<BasicFilterCondition>("basic_expression.conditions")

const showErrors = computed(() => !!Object.keys(errors.value).length)

const showRemoveButton = computed(() => {
  if (!values.basic_expression?.conditions) return false
  return values.basic_expression.conditions.length > 1
})

defineExpose({
  // allow parent components to ask for validation & return status
  validate: async () => {
    await validate()
    return meta.value?.valid ?? false
  },
})

watch(
  () => props.modelValue,
  (newFilter, oldFilter) => {
    if (oldFilter && deepEqual(newFilter, oldFilter)) return // no change
    _updateLocalState(newFilter)
  },
  { immediate: true, deep: true }
)

watch(
  () => values,
  (newValues) => {
    // if change is an update from props.modelValue changing, skip it
    if (!meta.value.dirty) return
    // there is a very slight type definition difference here that is
    // difficult to correct, but newValues is always a valid filter.
    // also cast input values (all strings) back to native (string, bool, etc)
    const filter = filterValuesToNative(newValues as Filter)
    emit("update:modelValue", filter)
  },
  { deep: true }
)

function addCondition() {
  pushCondition({ selector: "", operator: "==", value: "" })
}

function conditionKeyName(key: string | number) {
  return `basic_expression.conditions[${key}]`
}

function updateAny(value: string) {
  selectedAny.value = value
  setFieldValue("basic_expression.match_all", value === "All")
}

function _updateLocalState(filter: Filter | undefined) {
  if (!filter) return
  // internal state is cast to strings for use with inputs
  const filterForForm = filterValuesToStrings(filter)
  if (!deepEqual(filterForForm, values)) {
    // deepEqual compare to avoid infinite loop
    if (filter.basic_expression) {
      resetForm({ values: filterForForm }, { force: true })
    } else {
      resetForm() // reset to defaults
    }
    selectedAny.value = values.basic_expression?.match_all ? "All" : "Any"
  }
}
</script>

<template>
  <div class="bg-white border rounded border-slate-200">
    <div class="flex justify-end border-b border-slate-200">
      <div class="flex justify-end items-center py-2 pr-4">
        <span class="text-xs text-slate-400 px-2">
          include events that match
        </span>
        <VSelect
          v-model="selectedAny"
          :options="anyOptions"
          size="small"
          class="w-20"
          data-testid="any-select"
          @update="updateAny"
        />
        <span class="text-xs text-slate-400 pl-2">conditions</span>
      </div>
    </div>
    <div class="p-6">
      <VNotification
        v-if="showErrors"
        type="error"
        title="Your filter contains errors."
        size="small"
        class="mb-4"
      />
      <div
        v-for="(condition, index) in conditions"
        :key="condition.key"
        class="flex w-full space-x-2 pb-2"
      >
        <FilterEditorRow :name-prefix="conditionKeyName(index)" />
        <VButton
          v-if="showRemoveButton"
          color="secondary"
          class="w-8 h-8 !px-0 !py-0 justify-center"
          @click="removeCondition(index)"
        >
          <RemoveCircleIcon
            class="block w-5 h-5 text-slate-600 hover:text-red-500"
          />
        </VButton>
      </div>
      <VButton color="secondary" @click="addCondition">Add Condition</VButton>
    </div>
  </div>
</template>
