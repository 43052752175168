import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.625 17.378c0 .896-.706 1.622-1.577 1.622-.386 0-.76-.146-1.048-.41l-2.038-1.863a1.82 1.82 0 0 1-.587-1.345V10l-4.19-6.395c-.432-.89-.082-1.97.783-2.415.243-.125.51-.19.782-.19h10.5c.966 0 1.75.806 1.75 1.8 0 .28-.063.555-.185.805l-4.19 6.394zM10.875 10h-1.75v5.382l1.75 1.6zm4.375-7H4.75l3.54 5h3.414z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }