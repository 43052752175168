<script setup lang="ts">
// form for a single filter basic condition entry

import { OPERATORS } from "@/common/filter"

interface Props {
  namePrefix: string // prefix to use when linking form state
}

const props = withDefaults(defineProps<Props>(), {})

const filterComparisons = OPERATORS
</script>

<template>
  <div class="flex grow space-x-2">
    <VFormInput
      :name="props.namePrefix + '.selector'"
      placeholder="Select attribute..."
      :show-error-message="false"
      class="grow"
    />
    <VFormSelect
      :name="props.namePrefix + '.operator'"
      :options="filterComparisons"
      selected="equals"
      class="w-32"
    />
    <VFormInput
      :name="props.namePrefix + '.value'"
      placeholder="Enter value..."
      :show-error-message="false"
      class="grow"
    />
  </div>
</template>
