<script setup lang="ts">
import CompactMetric from "@/components/CompactMetric.vue"
import { getLatestMetricInstance, getTelemetryMetric } from "@/common/metrics"

import type { MetricInstance, TelemetryMetricType } from "@/types/types"

interface Props {
  metrics: MetricInstance[]
  observabilityTypes: TelemetryMetricType[]
  writesActive: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const instance = computed(() => getLatestMetricInstance(props.metrics))

const showLogs = computed(() => props.observabilityTypes.includes("logs"))
const showMetrics = computed(() => props.observabilityTypes.includes("metrics"))
const showTraces = computed(() => props.observabilityTypes.includes("traces"))

const logsIn = computed(() => getTelemetryMetric(instance, "logs", "in"))
const logsOut = computed(() => getTelemetryMetric(instance, "logs", "out"))

const metricsIn = computed(() => getTelemetryMetric(instance, "metrics", "in"))
const metricsOut = computed(() =>
  getTelemetryMetric(instance, "metrics", "out")
)

const tracesIn = computed(() => getTelemetryMetric(instance, "traces", "in"))
const tracesOut = computed(() => getTelemetryMetric(instance, "traces", "out"))

// only show units if data is present
function units(measurement: number): string {
  return measurement > 0 ? "/s" : ""
}
</script>

<template>
  <div class="bg-white border border-slate-200 shadow-01 rounded w-full mt-4">
    <h2 class="px-3 py-2 text-xs text-slate-600 font-semibold">
      Observability Data
    </h2>
    <div class="grid grid-cols-2">
      <CompactMetric
        v-if="showLogs"
        title="Logs In"
        :value="logsIn"
        :units="units(logsIn)"
        :disabled="!writesActive"
        class="border-t border-slate-100"
      />
      <CompactMetric
        v-if="showLogs"
        title="Logs Out"
        :value="logsOut"
        :units="units(logsOut)"
        :disabled="!writesActive"
        class="border-t border-l border-slate-100"
      />
      <CompactMetric
        v-if="showMetrics"
        title="Metrics In"
        :value="metricsIn"
        :units="units(metricsIn)"
        :disabled="!writesActive"
        class="border-t border-slate-100"
      />
      <CompactMetric
        v-if="showMetrics"
        title="Metrics Out"
        :value="metricsOut"
        :units="units(metricsOut)"
        :disabled="!writesActive"
        class="border-t border-l border-slate-100"
      />
      <CompactMetric
        v-if="showTraces"
        title="Trace Spans In"
        :value="tracesIn"
        :units="units(tracesIn)"
        :disabled="!writesActive"
        class="border-t border-slate-100"
      />
      <CompactMetric
        v-if="showTraces"
        title="Trace Spans Out"
        :value="tracesOut"
        :units="units(tracesOut)"
        :disabled="!writesActive"
        class="border-t border-l border-slate-100"
      />
    </div>
  </div>
</template>
