import { useDestinationStore } from "@/stores/destination"
import { useSourceStore } from "@/stores/source"

import type { Destination, Source } from "@/types/types"

// load resources - preferentially pull from cache if available, otherwise retrieve from API

interface GetResource {
  pending: Ref<boolean>
  error: Ref<Error | null>
}

interface _GetDestination extends GetResource {
  data: Ref<Destination | null>
}

interface _GetSource extends GetResource {
  data: Ref<Source | null>
}

type GetDestination = _GetDestination & Promise<_GetDestination>
type GetSource = _GetSource & Promise<_GetSource>

export const useGetDestination = (id: string): GetDestination => {
  const destStore = useDestinationStore()
  const cached = destStore.destinations.find((d) => d.id === id)
  if (cached) {
    // provide similar signature to useFetch
    const asyncData = {
      data: ref(cached),
      pending: ref(false),
      error: ref(undefined),
    }
    // TODO: allow for background refresh
    const promise = Promise.resolve(asyncData) as unknown as GetDestination
    Object.assign(promise, asyncData)
    return promise
  } else {
    return useAPIFetch<Destination>(`/destinations/${id}`)
  }
}

export const useGetSource = (id: string): GetSource => {
  const sourceStore = useSourceStore()
  const cached = sourceStore.sources.find((s) => s.id === id)
  if (cached) {
    // provide similar signature to useFetch
    const asyncData = {
      data: ref(cached),
      pending: ref(false),
      error: ref(undefined),
    }
    // TODO: allow for background refresh
    const promise = Promise.resolve(asyncData) as unknown as GetSource
    Object.assign(promise, asyncData)
    return promise
  } else {
    return useAPIFetch<Source>(`/sources/${id}`)
  }
}
