<script setup lang="ts">
import { watchOnce } from "@vueuse/core"
import ChevronDown from "@/assets/icons/chevron-down.svg?component"
import ChevronRight from "@/assets/icons/chevron-right.svg?component"
import FilterIcon from "@/assets/icons/filter.svg?component"
import FilterEditor from "@/components/FilterEditor.vue"
import { clone } from "@/utils/object"

import type { Filter, StreamFilter } from "@/types/types"

interface Props {
  filter: StreamFilter | undefined
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["remove", "update"])

const localFilter = reactive<StreamFilter>({
  label: "",
  basic_expression: { conditions: [], match_all: false },
})
const displayEditor = ref(false)
const filterPresent = ref(false)

// make public methods on child component available via ref
const filterEditor = ref<InstanceType<typeof FilterEditor> | null>(null)

const blankSlate = computed(() => !filterPresent.value && !displayEditor.value)

const showRemove = computed(
  () => props.filter?.expression || props.filter?.basic_expression
)

watch(
  () => props.filter,
  (newFilter) => _updateLocalState(newFilter),
  { immediate: true, deep: true }
)

function cancelChanges() {
  _updateLocalState(props.filter)
  closeEditor()
}

function closeEditor() {
  displayEditor.value = false
}

function removeFilter() {
  // display state will be reset when the filter prop updates
  emit("remove")
}

function showEditor() {
  displayEditor.value = true
}

function syncFilter(filter: Filter) {
  if (filter.basic_expression) {
    const basicExpression = filter.basic_expression
    localFilter.basic_expression = structuredClone(basicExpression)
  }
}

function toggleEdit() {
  displayEditor.value = !displayEditor.value
}

async function updateFilter() {
  const basicExpression = localFilter.basic_expression
  if (basicExpression?.conditions) {
    const valid = await filterEditor.value?.validate()
    if (!valid) return // user got error feedback

    emit("update", clone(localFilter))
    // close if update succeeds
    watchOnce(() => props.filter, closeEditor)
  }
}

function _resetLocalFilter() {
  localFilter.label = ""
  localFilter.basic_expression = {
    conditions: [{ selector: "", operator: "==", value: "" }],
    match_all: false,
  }
}

function _updateLocalState(filter: StreamFilter | undefined) {
  if (filter) {
    localFilter.label = filter.label || ""
    if (filter.basic_expression) {
      localFilter.basic_expression = structuredClone(
        toRaw(filter.basic_expression)
      )
      filterPresent.value = true
    } else {
      _resetLocalFilter()
      filterPresent.value = false
      closeEditor()
    }
  } else {
    _resetLocalFilter()
    filterPresent.value = false
  }
}
</script>

<template>
  <div>
    <div
      v-if="displayEditor || filterPresent"
      class="border bg-white border-grey-200 shadow-01 rounded box-border"
    >
      <div
        class="bg-white flex justify-between rounded"
        :class="{ '': displayEditor }"
      >
        <div class="flex p-4 border-r border-slate-100">
          <VPoint><FilterIcon class="h-5 w-5" /></VPoint>
        </div>
        <div class="flex grow">
          <div
            class="grow py-4 pl-4 flex items-center space-x-3 cursor-pointer select-none"
            @click="toggleEdit"
          >
            <div v-if="filterPresent" data-testid="toggle-chevron">
              <ChevronRight
                v-if="!displayEditor"
                class="text-slate-600 h-6 w-6"
              />
              <ChevronDown v-else class="text-indigo-600 h-6 w-6" />
            </div>
            <div class="text-slate-600 leading-none py-2 font-medium">
              Filter
            </div>
          </div>
          <div class="flex justify-end items-center p-4">
            <VInput
              v-if="displayEditor"
              v-model="localFilter.label"
              placeholder="Set an optional label..."
              class="w-96"
            />
            <span v-if="!displayEditor" class="text-slate-400 px-4">
              {{ localFilter.label }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="displayEditor" data-testid="editor">
        <div class="bg-slate-25 p-6 border-t border-slate-100">
          <div>
            <h3 class="text-sm font-medium text-slate-600">Stream Filter</h3>
            <p class="text-sm text-slate-400 mb-2">
              Include or exclude events matching the following conditions.
            </p>
          </div>
          <FilterEditor
            ref="filterEditor"
            :model-value="localFilter"
            @update:model-value="syncFilter"
          />
        </div>
        <VButtonBar>
          <VButton color="secondary" @click="cancelChanges">Cancel</VButton>
          <VButton color="positive" @click="updateFilter">Save</VButton>
          <template #left>
            <VButton v-show="showRemove" color="negative" @click="removeFilter">
              Remove Filter
            </VButton>
          </template>
        </VButtonBar>
      </div>
    </div>

    <div
      v-if="blankSlate"
      class="border border-dashed border-indigo-200 bg-indigo-25 flex rounded"
      data-testid="blank-slate"
    >
      <div class="p-4 flex">
        <VPoint light><FilterIcon class="h-5 w-5" /></VPoint>
      </div>
      <div class="grow py-4 text-center">
        <VButton @click="showEditor">Set Filter</VButton>
      </div>
    </div>
  </div>
</template>
