<script setup lang="ts">
import DocsLink from "@/components/DocsLink.vue"
import FilterEditor from "@/components/FilterEditor.vue"
import { FUNCTIONS } from "@/common/function"
import { clone } from "@/utils/object"
import { camelCase } from "@/utils/string"

import type { Filter, StreamFunction } from "@/types/types"

// generic type for component refs of function configuration components.
type FunctionConfigEditor = ComponentPublicInstance & {
  // tell typescript these components may expose validation
  validate(): Promise<boolean>
}

interface Props {
  streamFunction: StreamFunction
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["update"])

const displayFilter = ref(false)

// child component refs
const configEditor = ref<FunctionConfigEditor | null>(null)
const filterEditor = ref<InstanceType<typeof FilterEditor> | null>(null)

const localFunction = reactive<StreamFunction>({
  type: "drop", // specify default to satisfy type requirements
  label: "",
  filter: {},
  config: {},
})

defineExpose({
  // allow parent components to ask to validate & return status
  validate: async () => {
    let filterValid = true
    let configValid = true

    // validate filters if present
    if (showFilter.value && filterEditor.value) {
      filterValid = await filterEditor.value.validate()
    }
    // validate function config if config component supports it
    if (configEditor.value?.validate) {
      configValid = await configEditor.value.validate()
    }

    return filterValid && configValid
  },
})

const filterRequired = computed(() => functionType.value?.filterRequired)
const functionType = computed(() =>
  FUNCTIONS.find((f) => f.id === props.streamFunction.type)
)
const functionDescription = computed(() => functionType.value?.description)
const showFilter = computed(() => filterRequired.value || displayFilter.value)

const configComponent = computed(() => {
  // don't render config component for function types that don't have config
  if (functionType.value?.noConfig) return
  // dynamically import correct config component
  const componentName = camelCase(localFunction.type)
  return defineAsyncComponent(
    () => import(`../components/FunctionConfig/${componentName}.vue`)
  )
})

const docsPath = computed(() => {
  const integration = functionType.value?.integration
  const integrationPrefix: string = integration ? integration + "/" : ""
  return ["functions/", integrationPrefix, localFunction.type].join("")
})

watch(
  () => props.streamFunction,
  (newFunction) => _updateLocalState(newFunction),
  { immediate: true, deep: true }
)

function addFilter() {
  displayFilter.value = true
}

function removeFilter() {
  displayFilter.value = false
  localFunction.filter = {} // reset
  _emitUpdate()
}

function updateConfig(config: StreamFunction["config"]) {
  localFunction.config = config
  _emitUpdate()
}

function updateFilter(newFilter: Filter) {
  localFunction.filter = newFilter
  _emitUpdate()
}

function _emitUpdate() {
  emit("update", clone(localFunction))
}

function _updateLocalState(newFunc: StreamFunction) {
  localFunction.type = newFunc.type
  localFunction.label = newFunc.label
  localFunction.filter = newFunc.filter
  localFunction.config = newFunc.config
  displayFilter.value =
    (newFunc.filter?.basic_expression?.conditions.length || 0) > 0
}
</script>

<template>
  <div>
    <div
      class="flex px-4 py-2 bg-violet-25 border-y border-violet-100 justify-between items-center"
    >
      <span class="text-xs text-violet-800">
        {{ functionDescription }}
      </span>
      <div class="flex justify-end space-x-2">
        <DocsLink :to="docsPath">
          <VButton
            color="secondary"
            size="small"
            class="!text-indigo-500 !border-violet-200 !bg-white"
          >
            View Docs
          </VButton>
        </DocsLink>
        <VButton
          v-if="!filterRequired && !displayFilter"
          color="primary"
          size="small"
          @click="addFilter"
        >
          Add Filter
        </VButton>
        <VButton
          v-if="!filterRequired && displayFilter"
          color="negative"
          size="small"
          @click="removeFilter"
        >
          Remove Filter
        </VButton>
      </div>
    </div>

    <div
      v-show="showFilter"
      class="bg-slate-25 border-b border-slate-100 p-6"
      data-testid="filter"
    >
      <div>
        <h3 class="text-sm font-medium text-slate-600">Function Filter</h3>
        <p class="text-sm text-slate-400 mb-2">
          Operate on events that match the following conditions.
        </p>
      </div>
      <FilterEditor
        ref="filterEditor"
        :model-value="localFunction.filter"
        @update:model-value="updateFilter"
      />
    </div>
    <component
      :is="configComponent"
      v-if="configComponent"
      ref="configEditor"
      :config="localFunction.config"
      @update="updateConfig"
    />
  </div>
</template>
