<script setup lang="ts">
import { useForm } from "vee-validate"
import { UPDATE_STREAM } from "@/form_schemas/stream"

import type { Stream, StreamUpdate } from "@/types/types"

interface Props {
  stream: Stream | null
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(["update"])

const { handleSubmit, resetForm } = useForm({
  validationSchema: UPDATE_STREAM,
  initialValues: {
    name: "",
  },
})

watch(
  () => props.stream?.name,
  (newName) => {
    if (newName) resetForm({ values: { name: newName } })
  },
  { immediate: true }
)

const updateStream = handleSubmit((vals) => {
  const update: StreamUpdate = { name: vals.name }
  emit("update", update)
})
</script>

<template>
  <VSection>
    <VFormInput name="name" label="Name" />
    <template #footer>
      <div class="flex justify-end space-x-2">
        <VButton color="positive" @click="updateStream">Save</VButton>
      </div>
    </template>
  </VSection>
</template>
