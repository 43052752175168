<script setup lang="ts">
interface Props {
  state?: "disabled" | "error" | undefined
}

const props = withDefaults(defineProps<Props>(), {
  state: undefined,
})

const stateClasses: { [K in NonNullable<Props["state"]>]: string } = {
  disabled: "border-indigo-100 border-dotted",
  error: "border-red-300 border-dotted",
}
const classes = computed(() => {
  return props.state ? stateClasses[props.state] : "border-indigo-200"
})
</script>

<template>
  <div class="h-8">
    <div class="border-l-4 ml-8 h-8" :class="classes"></div>
  </div>
</template>
