<script setup lang="ts">
import CompactMetric from "@/components/CompactMetric.vue"
import StreamMetricsObservability from "@/components/StreamMetricsObservability.vue"
import { getLatestMetricInstance, getMeasurement } from "@/common/metrics"

import type { MetricInstance, TelemetryMetricType } from "@/types/types"

interface Props {
  metrics: MetricInstance[]
  observabilityTypes: TelemetryMetricType[]
  writesActive: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const latestMetric = computed(() => getLatestMetricInstance(props.metrics))

const eventsIn = computed(() => getMeasurement(latestMetric, "events_in"))
const eventsOut = computed(() => getMeasurement(latestMetric, "events_out"))

const showObservability = computed(() => !!props.observabilityTypes.length)

// only show units if data is present
function units(measurement: number): string {
  return measurement > 0 ? "/s" : ""
}
</script>

<template>
  <div>
    <!-- event metrics, always shown -->
    <div class="bg-white border border-slate-200 shadow-01 rounded w-full">
      <h2 class="px-3 py-2 text-xs text-slate-600 font-semibold">Events</h2>
      <div class="grid grid-cols-2">
        <CompactMetric
          title="In"
          :value="eventsIn"
          :units="units(eventsIn)"
          :disabled="!writesActive"
          class="border-t border-slate-100"
        />
        <CompactMetric
          title="Out"
          :value="eventsOut"
          :units="units(eventsOut)"
          :disabled="!writesActive"
          class="border-t border-l border-slate-100"
        />
      </div>
    </div>

    <!-- observability metrics, depend on source type -->
    <StreamMetricsObservability
      v-if="showObservability"
      :metrics="metrics"
      :observability-types="observabilityTypes"
      :writes-active="writesActive"
    />
  </div>
</template>
