import { inputToNativeValue, nativeValueToInput } from "@/utils/input"

import type {
  BasicFilterCondition,
  Filter,
  FilterOperators,
} from "~/types/types"

// The API filter operators list is exported as a string union in our generated
// types. We can't translate it to an array of strings with consistent ordering.
// see: https://stackoverflow.com/questions/44480644/string-union-to-string-array
// Keep this list up to date manually, but use the type to ensure operators are
// supported.
export const OPERATORS: FilterOperators[] = [
  "==",
  "!=",
  ">",
  ">=",
  "<",
  "<=",
  "contains",
  "matches",
]

// cast input strings to native values before returning for persistence
export function filterValuesToNative(filter: Filter): Filter {
  if (!filter.basic_expression?.conditions) return filter // nothing to do
  const basicExpression = filter.basic_expression
  const casted: BasicFilterCondition[] = []
  basicExpression.conditions.forEach((condition) => {
    casted.push({
      ...condition,
      value: inputToNativeValue(String(condition.value)),
    })
  })

  return {
    ...filter,
    basic_expression: { ...basicExpression, conditions: casted },
  }
}

// all form inputs need strings, so cast native values to strings
// in a way that is reversible
export function filterValuesToStrings(filter: Filter): Filter {
  if (!filter.basic_expression?.conditions) return filter // nothing to do
  const basicExpression = filter.basic_expression
  const casted: BasicFilterCondition[] = []
  basicExpression.conditions.forEach((condition) => {
    casted.push({ ...condition, value: nativeValueToInput(condition.value) })
  })

  return {
    ...filter,
    basic_expression: { ...basicExpression, conditions: casted },
  }
}
